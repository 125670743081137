@tailwind base;
@tailwind components;
@tailwind utilities;

.main-container {
  @apply w-10/12 mx-auto;
}

.main-heading {
  background: -webkit-linear-gradient(#478ce2, #053064);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.carousel-item {
  background: linear-gradient(
      186deg,
      rgba(50, 93, 157, 0.26) 35.31%,
      rgba(17, 74, 171, 0.26) 63.13%,
      rgba(234, 197, 81, 0.26) 88.59%
    ),
    url("./assets/images/slider-img1.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.carousel-item2 {
  background: linear-gradient(
      186deg,
      rgba(50, 93, 157, 0.26) 35.31%,
      rgba(17, 74, 171, 0.26) 63.13%,
      rgba(234, 197, 81, 0.26) 88.59%
    ),
    url("./assets/images/slider-img2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion {
  background: linear-gradient(180deg, #478ce2 0%, #053064 100%);
}

.register-bg {
  background: linear-gradient(
    180deg,
    rgba(71, 140, 226, 1) 0%,
    rgba(5, 48, 100, 1) 100%
  );
}

.register-bg .header {
  background: linear-gradient(rgba(237, 195, 27, 1), rgba(250, 221, 105, 1));
}

.no-border-radius {
  border-radius: 0px !important;
  background-color: #053064;
}

.custom-input div input,
.custom-textarea,
.custom-select div select {
  background-color: #fff !important;
  border: 1px solid #edc31b;
  color: #7b809a;
  border-radius: 5px;
  padding: 14px;
  outline: none;
  box-shadow: none;
}

.date-picker div div input {
  background-color: #fff !important;
  border: 1px solid #edc31b;
  color: #7b809a;
  border-radius: 5px;
  padding: 14px;
  outline: none;
  box-shadow: none;
  padding-left: 40px;
}

.date-picker .absolute div div .mb-2,
.date-picker .absolute div div .mb-2 button {
  background-color: #edc31b !important;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
}

.custom-input div input:focus,
.custom-select div select:focus,
.date-picker div div input:focus {
  border: 1px solid #edc31b;
  outline: none;
  box-shadow: none;
}

.card-bg {
  background: var(
    --Yellow-primary-color,
    linear-gradient(180deg, #edc31b 8%, #fadd69 100%)
  );
}

.timer-bg {
  background: var(
    --Orange-gradient-color,
    linear-gradient(180deg, #f9c473 0%, #db8b14 100%)
  );
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
}

.emotions-card {
  border-radius: 5px;
  background: var(
    --light-blue-gradient,
    linear-gradient(180deg, #73a9fb 0%, #4a85df 100%)
  );
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
}

.team-card {
  @apply col-span-12 md:mx-20 lg:mx-32 xl:mx-auto xl:col-span-6 rounded-lg bg-gray-100 hover:shadow-xl duration-700 my-10 hover:cursor-pointer px-3 py-3;
}

.team-card:hover .team-position {
  @apply -bottom-1;
}

.team-position {
  @apply bg-white shadow-lg w-11/12 mx-auto border-t-[10px] border-yellow100 absolute z-30 -bottom-5 left-3 duration-500;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px;
}

/* width */
.team-card ::-webkit-scrollbar {
  width: 3px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #edc31b;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #edc31b;
}

@media (min-width: 1280px) {
  .description-section {
    @apply h-[10.5rem];
  }
}

@media (min-width: 1550px) {
  .description-section {
    @apply h-[11.5rem];
  }
}

@media (min-width: 1650px) {
  .description-section {
    @apply h-[13rem];
  }
}

@media (min-width: 1690px) {
  .description-section {
    @apply h-[14.5rem];
  }
}
